// All of this should go away
import banner1 from '../../../images/banners/banner.jpg';
import banner2 from '../../../images/banners/banner1.jpg';

/* Extra Entries son los links que no tienen en común los menus mobile y desktop */

const banner1TextToTranslate = 'Transporte + Hotel + Entradas';
const banner1InfoToTranslate = '';
const banner1Destination = 'https://www.portaventuraworld.com/portaventura-holidays';
const banner2TextToTranslate = '¡Vive un Halloween escalofriante!';
const banner2InfoToTranslate = 'Del 21/09 al 10/11';
const banner2Destination = 'https://www.portaventuraworld.com/promociones';

export const hardcodedExtraEntriesMenu = [
  {
    type: 'internal',
    destination: '',
    parameters: [],
    textToTranslate: 'Mi PortAventura',
    icon: 'perfilMenu',
  },
  // {
  //   type: 'internal',
  //   destination: 'checkin',
  //   parameters: [],
  //   textToTranslate: 'Check-in hotel',
  //   // icon: 'movilMenu',
  // },
  {
    type: 'internal',
    destination: 'ficha_calendar',
    parameters: [''],
    textToTranslate: 'Horarios y calendario',
  },
];

export const hardcodedPromotions = [
  {
    type: 'internal',
    destination: 'ficha_promociones',
    parameters: ['descuento-entradas'],
    textToTranslate: 'Entrada de 2 días al precio de 1',
  },
  {
    type: 'internal',
    destination: 'semana_santa',
    parameters: [''],
    textToTranslate: 'Semana Santa',
  },
];

export const hardcodedMenu = [
  {
    type: 'root',
    textToTranslate: 'Entradas y paquetes',
    icon: 'ticket',
    children: [
      {
        type: 'level',
        textToTranslate: 'Promociones',
        children: [
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/promociones/hotel-entrada-halloween',
            parameters: [],
            textToTranslate: 'Este Halloween, hasta 20 % de DTO. en Hotel + Entradas',
          },
          {
            type: 'newsite',
            destination:
              'https://www.portaventuraworld.com/promociones/oferta-multidia-entrada-halloween',
            parameters: [],
            textToTranslate:
              '¡BAJAMOS PRECIOS! Entradas 2 días 2 parques desde 49 € (antes desde 62 €)',
          },
          {
            type: 'newsite',
            destination:
              'https://www.portaventuraworld.com/promociones/entradas-caribe-aquatic-park',
            parameters: [],
            textToTranslate: 'Caribe Aquatic Park desde 23 €/día',
          },
          {
            type: 'internal',
            textToTranslate: 'Ver todas las promociones',
            destination: 'distr_promociones',
            parameters: [],
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Entradas',
        children: [
          {
            type: 'internal',
            destination: 'entradas',
            parameters: [],
            textToTranslate: 'Entradas para PortAventura Park',
          },
          {
            type: 'internal',
            destination: 'entradas',
            parameters: [],
            textToTranslate: 'Entradas para Caribe Aquatic Park',
          },
          {
            type: 'internal',
            destination: 'entradas',
            parameters: [],
            textToTranslate: 'Entradas para Ferrari Land',
          },
          {
            type: 'internal',
            destination: 'entradas',
            parameters: [],
            textToTranslate: 'Ver todas las entradas',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Paquetes',
        children: [
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/portaventura-holidays',
            parameters: [],
            textToTranslate: 'Hotel + Entradas + Transporte',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/promociones/entradas-transporte',
            parameters: [],
            textToTranslate: 'Entradas + Transporte',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/hotel-entradas',
            parameters: [],
            textToTranslate: 'Hotel + Entradas',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Pases de temporada',
        children: [
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/pases-anuales/adventurer-pass',
            parameters: [],
            textToTranslate: 'Adventurer Pass',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/pases-anuales/explorer-pass',
            parameters: [],
            textToTranslate: 'Explorer Pass',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/pases-anuales/discoverer-pass',
            parameters: [],
            textToTranslate: 'Discoverer Pass',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/pases-anuales',
            parameters: [],
            textToTranslate: 'Ver todos los pases',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/auth/login',
            parameters: [],
            textToTranslate: 'Acceso al área privada',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Pases Express y otros productos',
        children: [
          {
            type: 'level',
            textToTranslate: 'Extras para los parques',
            children: [
              {
                type: 'internal',
                destination: 'pase_express',
                parameters: [],
                textToTranslate: 'Pases Express',
              },
              {
                type: 'internal',
                textToTranslate: 'Pasajes de Halloween',
                destination: 'pasajes_halloween',
                parameters: [],
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Extras para los hoteles',
            children: [
              {
                type: 'internal',
                destination: 'hotel_pase_express',
                parameters: [],
                textToTranslate: 'Pases Express',
              },
            ],
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Tarifas para grupos',
        children: [
          {
            type: 'internal',
            destination: 'ficha_grupos',
            parameters: ['grupos-vacacionales'],
            textToTranslate: 'Grupos vacacionales',
          },
          {
            type: 'level',
            textToTranslate: 'Grupos escolares',
            children: [
              {
                type: 'newsite',
                destination: 'https://www.portaventuraworld.com/grupos/grupos-escolares',
                parameters: [],
                textToTranslate: 'Escuelas',
              },
              {
                type: 'newsite',
                destination: 'https://www.portaventuraworld.com/grupos/ecoeduca',
                parameters: [],
                textToTranslate: 'EcoEduca',
              },
            ],
          },
          {
            type: 'external',
            destination:
              'https://www.portaventuraworld.com/blog/portaventura-corporate-regalo-equipo',
            parameters: [],
            textToTranslate: 'Team building',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Otras tarifas',
        children: [
          {
            type: 'internal',
            destination: 'ficha_grupos',
            parameters: ['familia-numerosa'],
            textToTranslate: 'Familias numerosas y monoparentales',
          },
          {
            type: 'internal',
            destination: 'ficha_grupos',
            parameters: ['personas-discapacidad-pmr'],
            textToTranslate: 'Tarifas para personas con discapacidad',
          },
        ],
      },
    ],
    banners: [
      {
        type: 'newsitebanner',
        destination: banner1Destination,
        parameters: [],
        src: banner1,
        textToTranslate: banner1TextToTranslate,
        infoToTranslate: banner1InfoToTranslate,
      },
      {
        type: 'newsitebanner',
        destination: banner2Destination,
        parameters: [],
        src: banner2,
        textToTranslate: banner2TextToTranslate,
        infoToTranslate: banner2InfoToTranslate,
      },
    ],
  },
  {
    type: 'root',
    textToTranslate: 'Parques',
    icon: 'mission',
    children: [
      {
        type: 'level',
        textToTranslate: 'PortAventura Park',
        children: [
          {
            type: 'internal',
            destination: 'ficha_mundos',
            parameters: [],
            textToTranslate: 'Ver todo PortAventura Park',
          },
          {
            type: 'internal',
            destination: 'distributiva_atracciones_pa',
            parameters: [],
            textToTranslate: 'Atracciones',
          },
          // {
          //   type: 'internal',
          //   destination: 'distributiva_espectaculos_pa',
          //   parameters: [],
          //   textToTranslate: 'Espectáculos',
          // },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/verano/espectaculos',
            parameters: [],
            textToTranslate: 'Espectáculos',
          },
          {
            type: 'internal',
            destination: 'distributiva_restaurantes_pa',
            parameters: [],
            textToTranslate: 'Restaurantes',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_pa',
            parameters: [],
            textToTranslate: 'Tiendas',
          },
          {
            type: 'internal',
            destination: 'distributiva_mundos',
            parameters: [],
            textToTranslate: 'Nuestros mundos',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Caribe Aquatic Park',
        children: [
          {
            type: 'internal',
            destination: 'ficha_parques',
            parameters: ['aquatic-park'],
            textToTranslate: 'Ver todo Caribe Aquatic Park',
          },
          {
            type: 'internal',
            destination: 'distributiva_atracciones_aq',
            parameters: [],
            textToTranslate: 'Atracciones',
          },
          {
            type: 'internal',
            destination: 'distributiva_restaurantes_aq',
            parameters: [],
            textToTranslate: 'Restaurantes',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_aq',
            parameters: [],
            textToTranslate: 'Tiendas',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Ferrari Land',
        children: [
          {
            type: 'internal',
            destination: 'ficha_parques',
            parameters: ['ferrari-land'],
            textToTranslate: 'Ver todo Ferrari Land',
          },
          {
            type: 'internal',
            destination: 'distributiva_atracciones_fl',
            parameters: [],
            textToTranslate: 'Atracciones',
          },
          {
            type: 'internal',
            destination: 'distributiva_espectaculos_fl',
            parameters: [],
            textToTranslate: 'Espectáculos',
          },
          {
            type: 'internal',
            destination: 'distributiva_restaurantes_fl',
            parameters: [],
            textToTranslate: 'Restaurantes',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_fl',
            parameters: [],
            textToTranslate: 'Tiendas',
          },
        ],
      },
      {
        type: 'internal',
        destination: 'distributiva_parques',
        parameters: [],
        textToTranslate: 'Ver los parques de PortAventura World',
      },
    ],
    banners: [
      {
        type: 'newsitebanner',
        destination: banner1Destination,
        parameters: [],
        src: banner1,
        textToTranslate: banner1TextToTranslate,
        infoToTranslate: banner1InfoToTranslate,
      },
      {
        type: 'newsitebanner',
        destination: banner2Destination,
        parameters: [],
        src: banner2,
        textToTranslate: banner2TextToTranslate,
        infoToTranslate: banner2InfoToTranslate,
      },
    ],
  },
  {
    type: 'root',
    textToTranslate: 'Hoteles',
    icon: 'hotel',
    children: [
      {
        type: 'internal',
        destination: 'checkin',
        parameters: [],
        classname: 'checkinHotel',
        textToTranslate: 'Check-in hotel',
      },
      {
        type: 'level',
        textToTranslate: 'Hoteles de PortAventura World',
        children: [
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['mansion-de-lucy', ''],
            textToTranslate: 'Hotel Mansion de Lucy',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['colorado-creek', ''],
            textToTranslate: 'Hotel Colorado Creek',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['gold-river', ''],
            textToTranslate: 'Hotel Gold River',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['port-aventura', ''],
            textToTranslate: 'Hotel Portaventura',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['caribe', ''],
            textToTranslate: 'Hotel Caribe',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['el-paso', ''],
            textToTranslate: 'Hotel El Paso',
          },
          {
            type: 'internal',
            destination: 'distributiva_hoteles',
            parameters: [],
            textToTranslate: 'Todos los hoteles de PortAventura World',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Ponient Hotels',
        children: [
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/hoteles/vila-centric',
            parameters: [],
            textToTranslate: 'Ponient Vila Centric',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/hoteles/piramide-salou',
            parameters: [],
            textToTranslate: 'Ponient Pirámide Salou',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/hoteles/dorada-palace',
            parameters: [],
            textToTranslate: 'Ponient Dorada Palace',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/hoteles-afiliados',
            parameters: [],
            textToTranslate: 'Todos los hoteles Ponient',
          },
        ],
      },
      {
        type: 'internal',
        destination: 'distributiva_hoteles',
        parameters: [],
        textToTranslate: 'Todos los hoteles',
      },
      {
        type: 'level',
        textToTranslate: 'Otras opciones de alojamiento',
        children: [
          {
            type: 'internal',
            destination: 'hotel_roulette',
            parameters: [],
            textToTranslate: 'Hotel Roulette',
          },
          {
            type: 'internal',
            destination: 'ficha_hoteles',
            parameters: ['estacionamiento-autocaravanas', ''],
            textToTranslate: 'Parking Caravaning',
          },
        ],
      },
    ],
    banners: [
      {
        type: 'newsitebanner',
        destination: banner1Destination,
        parameters: [],
        src: banner1,
        textToTranslate: banner1TextToTranslate,
        infoToTranslate: banner1InfoToTranslate,
      },
      {
        type: 'newsitebanner',
        destination: banner2Destination,
        parameters: [],
        src: banner2,
        textToTranslate: banner2TextToTranslate,
        infoToTranslate: banner2InfoToTranslate,
      },
    ],
  },
  {
    type: 'root',
    textToTranslate: 'Planifica tu visita',
    icon: 'mapa',
    children: [
      {
        type: 'level',
        textToTranslate: 'Información útil',
        children: [
          {
            type: 'internal',
            destination: 'plan_your_stay',
            parameters: [],
            textToTranslate: 'Planifica tu viaje a PortAventura World',
          },
          {
            type: 'internal',
            textToTranslate: 'Servicios en los parques',
            destination: 'servicios_parques',
            parameters: [],
          },
          {
            type: 'internal',
            destination: 'pys_recomendaciones',
            parameters: [],
            textToTranslate: 'Consejos y recomendaciones',
          },
          {
            type: 'internal',
            destination: 'pys_trucos',
            parameters: [],
            textToTranslate: 'Trucos',
          },
          {
            type: 'internal',
            destination: 'preguntas_frecuentes',
            parameters: [''],
            textToTranslate: 'Preguntas frecuentes',
          },
          {
            type: 'level',
            textToTranslate: 'Novedades',
            children: [
              {
                type: 'internal',
                destination: 'laliga_thebeatchallenge',
                parameters: [],
                textToTranslate: 'LaLiga 29’s',
              },
            ],
          },
        ],
      },
      {
        type: 'newsite',
        destination: 'https://www.portaventuraworld.com/app',
        parameters: [],
        textToTranslate: 'App PortAventura World',
      },
      {
        type: 'level',
        textToTranslate: 'Eventos temáticos',
        children: [
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/carnaval',
            parameters: [],
            textToTranslate: 'Carnaval',
          },
          {
            type: 'internal',
            destination: 'semana_santa',
            parameters: [],
            textToTranslate: 'Semana Santa',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/verano',
            parameters: [],
            textToTranslate: 'Verano',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/halloween',
            parameters: [],
            textToTranslate: 'Halloween',
          },
          {
            type: 'newsite',
            destination: 'https://www.portaventuraworld.com/navidad',
            parameters: [],
            textToTranslate: 'Navidad',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Espectáculos',
        children: [
          {
            type: 'level',
            textToTranslate: 'Espectáculos de PortAventura Park',
            children: [
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['', 'aventuras-tadeo-jones'],
                textToTranslate: 'Las aventuras de Tadeo Jones',
              },
              {
                type: 'newsite',
                destination: 'https://www.portaventuraworld.com/espectaculos/magic-bubble',
                parameters: [],
                textToTranslate: 'Magic Bubble',
              },
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['', 'lets-dance'],
                textToTranslate: 'Lets Dance',
              },
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['', 'west-gold-frenzy'],
                textToTranslate: 'West Gold Frenzy',
              },
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['', 'aloha-tahiti'],
                textToTranslate: 'Aloha Tahití',
              },
              {
                type: 'internal',
                destination: 'distributiva_espectaculos_pa',
                parameters: [],
                textToTranslate: 'Todos los espectáculos en PortAventura Park',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Espectáculos de Ferrari Land',
            children: [
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['ferrari-land', 'acrobatic-show-ferrari-land'],
                textToTranslate: 'Acrobatic Show',
              },
              {
                type: 'internal',
                destination: '',
                parameters: ['ferrari-land', 'rondo-veneziano'],
                textToTranslate: 'Rondò Veneziano',
              },
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['ferrari-land', 'folklore-italiano'],
                textToTranslate: 'Folklore Italiano',
              },
              {
                type: 'internal',
                destination: 'ficha_espectaculos',
                parameters: ['ferrari-land', 'bmx-show'],
                textToTranslate: 'BMX Show',
              },
              {
                type: 'internal',
                destination: 'distributiva_espectaculos_fl',
                parameters: [],
                textToTranslate: 'Todos los espectáculos en Ferrari Land',
              },
            ],
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Atracciones',
        children: [
          {
            type: 'level',
            textToTranslate: 'Atracciones de PortAventura Park',
            children: [
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'dragon-khan'],
                textToTranslate: 'Dragon Khan',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'shambhala'],
                textToTranslate: 'Shambhala',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'furius-baco'],
                textToTranslate: 'Furius Baco',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'tutuki-splash'],
                textToTranslate: 'Tutuki Splash',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'angkor'],
                textToTranslate: 'Angkor',
              },
              {
                type: 'internal',
                destination: 'distributiva_atracciones_pa',
                parameters: [],
                textToTranslate: 'Ver todas las atracciones de PortAventura Park',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Atracciones de Ferrari Land',
            children: [
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['ferrari-land', 'red-force'],
                textToTranslate: 'Red Force',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['ferrari-land', 'flying-dreams'],
                textToTranslate: 'Flying Dreams',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['ferrari-land', 'racing-legends'],
                textToTranslate: 'Racing Legends',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['ferrari-land', 'maranello-grand-race'],
                textToTranslate: 'Maranello Grand Race',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['ferrari-land', 'crazy-pistons'],
                textToTranslate: 'Crazy Pistons',
              },
              {
                type: 'internal',
                destination: 'distributiva_atracciones_fl',
                parameters: [],
                textToTranslate: 'Ver todas las atracciones de Ferrari Land',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Atracciones de Caribe Aquatic Park',
            children: [
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'king-khajuna'],
                textToTranslate: 'King Khajuna',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'barracudas'],
                textToTranslate: 'Barracudas',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'el-torrente'],
                textToTranslate: 'El torrente',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'rapid-race'],
                textToTranslate: 'Rapid Race',
              },
              {
                type: 'internal',
                destination: 'ficha_atracciones',
                parameters: ['', 'mambo-limbo'],
                textToTranslate: 'Mambo Limbo',
              },
              {
                type: 'internal',
                destination: 'distributiva_atracciones_ap',
                parameters: [],
                textToTranslate: 'Ver todas las atracciones de Caribe Aquatic Park',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Entorno',
            children: [
              {
                type: 'internal',
                destination: 'ficha_wellness',
                parameters: ['beach-club'],
                textToTranslate: 'Beach Club Lumine',
              },
              {
                type: 'internal',
                destination: 'ficha_entorno',
                parameters: ['costa-dorada', 'poblaciones-cercanas'],
                textToTranslate: 'Salou',
              },
              {
                type: 'internal',
                destination: 'ficha_entorno',
                parameters: ['costa-dorada', 'poblaciones-cercanas/reus'],
                textToTranslate: 'Reus',
              },
              {
                type: 'internal',
                destination: 'ficha_entorno',
                parameters: ['costa-dorada', 'poblaciones-cercanas/tarragona'],
                textToTranslate: 'Tarragona',
              },
              {
                type: 'internal',
                destination: 'ficha_destiny',
                parameters: ['costa-dorada'],
                textToTranslate: 'Costa Dorada',
              },

              {
                type: 'internal',
                destination: 'ficha_destiny',
                parameters: ['barcelona'],
                textToTranslate: 'Barcelona',
              },
              {
                type: 'internal',
                destination: 'ficha_destiny',
                parameters: ['cataluna'],
                textToTranslate: 'Turisme de Catalunya',
              },
            ],
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Restaurantes',
        children: [
          {
            type: 'level',
            textToTranslate: 'Restaurantes de PortAventura Park',
            children: [
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'marco-polo'],
                textToTranslate: 'Marco Polo',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'sichuan'],
                textToTranslate: 'Sichuan',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'bora-bora'],
                textToTranslate: 'Bora Bora',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'the-iron-horse'],
                textToTranslate: 'The Iron Horse',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'la-cantina'],
                textToTranslate: 'La Cantina',
              },
              {
                type: 'internal',
                destination: 'distributiva_restaurantes_pa',
                parameters: [],
                textToTranslate: 'Ver todos los restaurantes',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Restaurantes de Ferrari Land',
            children: [
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['ferrari-land', 'ristorante-cavallino'],
                textToTranslate: 'Ristorante Cavallino',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['ferrari-land', 'pite-lane'],
                textToTranslate: 'Pit-Lane',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['ferrari-land', 'ice-cream-box'],
                textToTranslate: 'Ice Cream Box',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['ferrari-land', 'paddock'],
                textToTranslate: 'Paddock',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['ferrari-land', 'finish-line'],
                textToTranslate: 'Finish Line',
              },
              {
                type: 'internal',
                destination: 'distributiva_restaurantes_fl',
                parameters: [],
                textToTranslate: 'Ver todos los restaurantes',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Restaurantes de Caribe Aquatic Park',
            children: [
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'reggae-cafe'],
                textToTranslate: 'Reggae Café',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'focacceria'],
                textToTranslate: 'Focacceria',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'la-cabana'],
                textToTranslate: 'La Cabaña',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'the-surfer'],
                textToTranslate: 'The Surfer',
              },
              {
                type: 'internal',
                destination: 'ficha_restaurantes',
                parameters: ['', 'puntos-de-alimentacion-aquatic-park'],
                textToTranslate: 'Puntos de alimentación',
              },
              {
                type: 'internal',
                destination: 'distributiva_restaurantes_ap',
                parameters: [],
                textToTranslate: 'Ver todos los restaurantes',
              },
            ],
          },
          {
            type: 'level',
            textToTranslate: 'Restaurantes de nuestros hoteles',
            children: [
              {
                type: 'internal',
                destination: 'ficha_hotel_restaurante',
                parameters: ['port-aventura', 'buffet-del-port'],
                textToTranslate: 'Buffet del Port',
              },
              {
                type: 'internal',
                destination: 'ficha_hotel_restaurante',
                parameters: ['port-aventura', 'el-xiringuito'],
                textToTranslate: 'El Xiringuito',
              },
              {
                type: 'internal',
                destination: 'ficha_hotel_restaurante',
                parameters: ['port-aventura', 'port-regata'],
                textToTranslate: 'Port Regata',
              },
              {
                type: 'internal',
                destination: 'ficha_hotel_restaurante',
                parameters: ['gold-river', 'gold-river-saloon'],
                textToTranslate: 'Gold River Saloon',
              },
              {
                type: 'internal',
                destination: 'ficha_hotel_restaurante',
                parameters: ['gold-river', 'grand-hall-buffet'],
                textToTranslate: 'Grand Hall Buffet',
              },
            ],
          },
          {
            destination: 'laliga_twenty_nines',
            type: 'internal',
            parameters: [],
            textToTranslate: 'Restaurante LaLiga TwentyNine’s',
          },
          {
            destination: 'veganos',
            type: 'internal',
            parameters: [],
            textToTranslate: 'Comida vegetariana',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Alojamiento',
        children: [
          {
            type: 'internal',
            destination: 'distributiva_hoteles',
            parameters: [],
            textToTranslate: 'Hoteles de PortAventura World',
          },
          {
            type: 'newsite',
            destination: 'http://localhost:3000/hoteles-afiliados',
            parameters: [],
            textToTranslate: 'Ponient Hotels',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Tiendas',
        children: [
          {
            type: 'external',
            destination: 'https://portaventurastore.com/es/',
            textToTranslate: 'Tienda online',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_pa',
            parameters: [],
            textToTranslate: 'Tiendas de PortAventura Park',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_ap',
            parameters: [],
            textToTranslate: 'Tiendas de Caribe Aquatic Park',
          },
          {
            type: 'internal',
            destination: 'distributiva_tiendas_fl',
            parameters: [],
            textToTranslate: 'Tiendas de Ferrari Land',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Nuevos negocios',
        children: [
          {
            type: 'internal',
            destination: 'laliga_twenty_nines',
            textToTranslate: "La Liga Twentynine's",
          },
          {
            type: 'internal',
            destination: 'laliga_thebeatchallenge',
            textToTranslate: 'The Beat challenge',
          },
          {
            type: 'external',
            destination: 'https://www.portaventuraworld.com/realidad-virtual',
            textToTranslate: 'PortAventura VRX',
          },
        ],
      },
      {
        type: 'level',
        textToTranslate: 'Cómo llegar',
        children: [
          {
            type: 'internal',
            destination: 'como_llegar',
            parameters: [],
            textToTranslate: 'Cómo llegar a PortAventura World',
          },
          {
            type: 'internal',
            destination: 'traslados',
            parameters: [],
            textToTranslate: 'Traslados a PortAventura World',
          },
        ],
      },
    ],
    banners: [
      {
        type: 'newsitebanner',
        destination: banner1Destination,
        parameters: [],
        src: banner1,
        textToTranslate: banner1TextToTranslate,
        infoToTranslate: banner1InfoToTranslate,
      },
      {
        type: 'newsitebanner',
        destination: banner2Destination,
        parameters: [],
        src: banner2,
        textToTranslate: banner2TextToTranslate,
        infoToTranslate: banner2InfoToTranslate,
      },
    ],
  },
  {
    type: 'internal',
    destination: 'ficha_calendar',
    icon: 'calendar',
    parameters: [''],
    textToTranslate: 'Horarios',
  },
  {
    type: 'external',
    icon: 'blog',
    destination: 'https://www.portaventuraworld.com/blog',
    textToTranslate: 'Blog',
  },
];

export const footerMenuInfo = [
  {
    type: 'level',
    textToTranslate: 'Business & Convention Center',
    children: [
      {
        type: 'external',
        destination: 'https://www.portaventuraevents.com/centro-de-convenciones/',
        textToTranslate: 'Convention Center',
        parameters: [],
      },
      {
        type: 'external',
        destination: 'https://www.portaventuraevents.com/teambuilding/',
        textToTranslate: 'Team building',
        parameters: [],
      },
      {
        type: 'external',
        destination: 'https://www.portaventuraevents.com/eventos-a-medida/',
        textToTranslate: 'Eventos a medida',
        parameters: [],
      },
      {
        type: 'external',
        destination: 'https://www.portaventuraevents.com/donde-estamos/',
        textToTranslate: 'Dónde estamos',
        parameters: [],
      },
    ],
  },
  {
    type: 'level',
    textToTranslate: 'Información corporativa',
    children: [
      {
        type: 'newsite',
        destination: 'https://www.portaventuraworld.com/consejo-de-administracion-e-inversores',
        parameters: [],
        textToTranslate: 'Consejo de Administración e inversores',
      },
      {
        type: 'newsite',
        destination: '/somos-b-corp',
        parameters: [],
        textToTranslate: 'B Corp',
      },
      {
        type: 'newsite',
        destination: '/nuestros-valores',
        parameters: [],
        textToTranslate: 'Propositos y valores',
      },
      {
        textToTranslate: 'Responsabilidad corporativa',
        type: 'internal',
        destination: 'nuestro_compromiso',
        parameters: [],
      },
      {
        textToTranslate: 'Medio Ambiente',
        type: 'internal',
        destination: 'medio_ambiente',
        parameters: [],
      },
      {
        textToTranslate: 'Código ético',
        type: 'newsite',
        destination:
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/003/372/original/Códifo_Ético_mundial_para_el_Turismo_(por_un_turismo_responsable).pdf1470647267.pdf',
        parameters: [],
      },
      {
        textToTranslate: 'Fundación PortAventura',
        type: 'internal',
        destination: 'fundacion',
        parameters: [],
      },
      {
        textToTranslate: 'Sobre nosotros',
        type: 'internal',
        destination: 'nosotros',
        parameters: [],
      },
      // {
      //   textToTranslate: '- Premios',
      //   type: 'internal',
      //   destination: 'reconocimientos',
      //   parameters: [],
      // },
      {
        textToTranslate: 'Partners',
        type: 'internal',
        destination: 'partners',
        parameters: [],
      },
      {
        textToTranslate: 'Trabaja con nosotros',
        type: 'internal',
        destination: 'trabajar_nosotros',
        parameters: [],
      },
      {
        textToTranslate: 'Contacto',
        type: 'internal',
        destination: 'contacto',
        parameters: [],
      },
    ],
  },
  {
    type: 'level',
    textToTranslate: 'Agencias y proveedores',
    children: [
      {
        textToTranslate: 'Portal de proveedores',
        type: 'external',
        destination: 'https://portaventura.bravosolution.com/web/login.shtml',
      },
      {
        textToTranslate: 'Agencias de viajes',
        type: 'internal',
        destination: 'agencia_viajes',
        parameters: [],
      },
    ],
  },
  {
    type: 'level',
    textToTranslate: 'Prensa',
    children: [
      {
        textToTranslate: 'Notas de prensa',
        type: 'internal',
        destination: 'ficha_prensa',
        parameters: ['notas-de-prensa-2023'],
      },
      {
        textToTranslate: 'Contacto de prensa',
        type: 'internal',
        destination: 'gabinete_prensa',
        parameters: [],
      },
      {
        textToTranslate: 'Dosier',
        type: 'internal',
        destination: 'dossier_prensa',
        parameters: [],
      },
      {
        textToTranslate: 'Material gráfico',
        type: 'internal',
        destination: 'material_grafico',
        parameters: [],
      },
    ],
  },
  {
    type: 'level',
    textToTranslate: 'Preguntas frecuentes',
    children: [
      {
        textToTranslate: 'PortAventura Park',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: [''],
      },
      {
        textToTranslate: 'PortAventura Caribe Aquatic Park',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['aquatic-park'],
      },
      {
        textToTranslate: 'Pase Express',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['pase-express'],
      },
      {
        textToTranslate: 'Entradas para los parques',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['entradas-a-los-parques'],
      },
      {
        textToTranslate: 'Hoteles de PortAventura World',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['hoteles-de-portaventura'],
      },
      {
        textToTranslate: 'Pases',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['pases-anuales'],
      },
      {
        textToTranslate: 'Oferta de restauración',
        type: 'internal',
        destination: 'preguntas_frecuentes',
        parameters: ['oferta-de-restauracion'],
      },
      {
        textToTranslate: 'Medidas especiales COVID-19',
        type: 'internal',
        destination: 'closed',
        parameters: [],
      },
    ],
  },
];

export const legalOptions = [
  {
    textToTranslate: 'Normas del resort',
    type: 'external',
    destination:
      'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/016/893/original/Normas_del_resort_ES_2023.pdf',
  },
  {
    textToTranslate: 'Condiciones generales',
    type: 'internal',
    destination: 'condiciones_generales',
    parameters: [],
  },
  {
    textToTranslate: 'Política de seguridad de la información',
    type: 'external',
    destination:
      'https://portaventura-world-production-files.s3.eu-west-1.amazonaws.com/wilson_cms/documents/documents/000/008/1000/POL%C3%8DTICA_DE_LA_SEGURIDAD_DE_LA_INFORMACI%C3%93N_PARA_PUBLICAR_def.pdf',
  },
  {
    textToTranslate: 'Política de privacidad',
    type: 'internal',
    destination: 'politica_privacidad',
    parameters: [],
  },

  {
    textToTranslate: 'Política de cookies',
    type: 'cookie',
  },
];

export const contactOptions = [
  {
    textToTranslate: 'Formulario de contacto',
    type: 'internal',
    icon: 'formularioMenu',
    destination: 'contacto',
    parameters: [],
  },
];
